
const initialState = {
  lang: "de_DE",
  correspondingLangSlug: "/",
  // Order process
  orderInfo: {
    userId: "",
    ust: "",
    orderType: "",
    isUpdate: false,
    isUpdateAddons: false,
    licenseType: "Node-lock",
    licenseAmount: 1,
    isEducational: false,
    isEducationalAddons: false,
    priceCalculated: 0,
    wantsNewsletter: false,
  },
  addonOrders: [
    // {id: 234, count: 0, title: "", subtitle: "Type 301", price: ""}
  ],
  showOrderFab: 0,
  // shippingAddress: {
  //   firstName: "Max",
  //   lastName: "Mustermann",
  //   organization: "Musterfirma GmbH",
  //   department: "Musterabteilung",
  //   street: "Musterstraße 1",
  //   postalCode: "70176",
  //   city: "Stuttgart",
  //   country: "Deutschland",
  //   email: "max.mustermann@nevernull.io",
  //   phone: "0152123456789",
  // },
  shippingAddress: {
    firstName: "",
    lastName: "",
    organization: "",
    department: "",
    street: "",
    postalCode: "",
    city: "",
    country: "",
    email: "",
    phone: "",
  },
  billingIsSame: true,
  billingAddress: {
    firstName: "",
    lastName: "",
    organization: "",
    department: "",
    street: "",
    postalCode: "",
    city: "",
    country: "",
    email: "",
    phone: "",
  },
  // supportInfo: {
  //   advancedSupport: true,
  //   email1: "max1.mustermann@nevernull.io",
  //   email2: "max2.mustermann@nevernull.io",
  //   email3: "max3.mustermann@nevernull.io",
  // }
  supportInfo: {
    advancedSupport: false,
    email1: "",
    email2: "",
    email3: "",
  }
}

export default initialState
