import i18n from "i18next"
import { initReactI18next } from "react-i18next"

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  de_DE: {
    translation: {
      orderInfoUpdateText: "Der reduzierte Preis gilt nur bei einem Update von Trnsys17 auf Trnsys18. Bitte geben sie hier Ihre Trnsys17 User-ID ein.",
      orderInfoUpdateAddonsText: "Der reduzierte Preis gilt nur bei einem Update von Trnsys17 auf Trnsys18.",

      userIdLabel: "TRNSYS User-ID (z.B. 17-D0100)",
      userIdLabelAddons: "TRNSYS User-ID (z.B. 18-D0100)",
      addressSameText: "Die Rechnungsanschrift entspricht der Lieferanschrift.",
      addedSupportText: "Erweiterten Support für 600€ dazu bestellen.",
      supportText: "Bitte tragen Sie 3 E-Mails ein, die mit dem erweiterten Support in Verbindung stehen können.",
      newsletterText1: "Wir, die Transsolar Energietechnik GmbH, möchten Sie regelmäßig per E-Mail über Software-updates, Weiterbildungskurse und Newsletter für TRNSYS informieren. Dafür benötigen wir Ihre Zustimmung. Diese kann zu jederzeit gegenüber der Transsolar Energietechnik GmbH widerrufen werden.",
      newsletterText2: "Ja, ich möchte regelmäßig Informationen zur Software TRNSYS erhalten.",
      newsletterText3: "Nein, ich möchte keine Informationen zur Software TRNSYS erhalten.",
      printOrderText1: "Bitte das Bestellformular ausdrucken, unterschreiben und uns zusenden.",
      printOrderText2: "Vielen Dank für Ihre Bestellung!",
      paymentConditionsText1: "Innerhalb der BRD kann die Lieferung auf Rechnung erfolgen. Nettopreise gültig ab dem ",
      paymentConditionsText2: " für drei Monate. Bezahlung innerhalb 14 Tagen rein netto; Lieferungen ins Ausland nur gegen Vorauszahlung.",
      copyrightText: "Die Software ist nur zum eigenen Gebrauch bestimmt, das Copyright liegt bei: Solar Energy Laboratory, University of Wisconsin (USA) und TRANSSOLAR, Stuttgart.",
      discountUpdateText100: "Rabatt - Update Libraries von TRNSYS17 auf TRNSYS18",
      discountUpdateText50: "Rabatt - Kauf der Libraries mit Hochschullizenz",
      discountText25: "Rabatt - Bestellung von 5 - 9 Libraries",
      discountText35: "Rabatt - Bestellung von 10 - 13 Libraries",
      discountText50: "Rabatt - Bestellung von 14 Libraries",
      discountTranssolar70: "Rabatt 70% - Update Transsolar Non-standard Types (max. 440,00 € Gesamtkosten)",

      trnsysInfoPageTitle0: "Lizenz-Informationen:",
      trnsysInfoPageTitle1: "Industrielizenz:",
      trnsysInfoPageTitle2: "Hochschullizenz:",
      trnsysInfoPageTitle3: "Lizenzierung:",
      trnsysInfoPageTitle4: "Node-lock (Computer gebunden):",
      trnsysInfoPageTitle5: "Floating License:",
      trnsysInfoPageTitle6: "Support:",
      trnsysInfoPageTitle7: "Basis-Support",
      trnsysInfoPageTitle8: "Erweiterter Support",
      trnsysInfoPageTitle9: "Modellierungsberatung",
      trnsysInfoPageTitle11: "Updates:",
      trnsysInfoPageText1: "Die TRNSYS Industrielizenz kann für 1, 5, 10, 20, 30, 40, oder 50 Nutzer eines Orts bestellt werden. Als Ort wird eine Abteilung eines Unternehmens an einem Standort betrachtet, welches die TRNSYSLizenz besitzt. Geschäftsstellen an anderen Standorten gelten als separater Ort. Alle Nutzer müssen Angehörige des Lizenznehmers sein.",
      trnsysInfoPageText2: "Hochschullizenzen sind für die Lehre und Forschung an Instituten bestimmt, welche Hochschulabschlüsse verleihen. Hochschullizenzen können für 10, 20, 30, 40, 50, 60, oder 70 Nutzer eines Ortes, für eine Fakultät oder Forschungsgruppe, bestellt werden. TRNSYS darf nicht an andere Gruppen des selben Instituts weitergegeben werden. Alle Nutzer müssen Angehörige des Lizenznehmers sein.",
      trnsysInfoPageText3: "Lizenznehmer können zwischen zwei Lizenz-Optionen wählen. Diese beiden Optionen können nicht gemischt werden.",
      trnsysInfoPageText4: "Pro TRNSYS-Lizenz darf der Nutzer eine Instanz der Software an einem Computer installieren. Die Anzahl der Personen, welche die Software zu einem gegebenen Zeitpunkt nutzen, darf die Anzahl der Nutzer pro Lizenz nicht überschreiten (Beispiel: bei einer 10er Lizenz dürfen max. 10 Personen das Programm zu einem Zeitpunkt benutzen).",
      trnsysInfoPageText5: "Mit der Floating License können Nutzer Lizenzen von jedem Computer nutzen, vorausgesetzt TRNSYS 18 ist installiert und der Computer besitzt eine Verbindung zum Internet. Ein zentraler Internetserver vergibt Lizenzen dynamisch, diese sind nicht mehr an einzelne Computer gebunden. Bei diesem System ist eine permanente Internetverbindung nötig. (Beispiel: bei einer 10er Lizenz dürfen max. 10 Personen das Programm zu einem Zeitpunkt benutzen).",
      trnsysInfoPageText6: "Ausschließlich registrierte TRNSYS-Anwender erhalten technischen Support. Bei jeder Anfrage muss eine gültige TRNSYS User-ID mitgesandt werden.",
      trnsysInfoPageText7: "Basis-Support bezüglich Installation oder Software-Fehlern wird gebürenfrei gewährt.",
      trnsysInfoPageText8: "Erweiterter Support im Bezug auf die Software kann für Zeiträume von zwei Jahren erworben werden. Support-Anfragen finden per E-Mail statt und werden möglichst innerhalb eines Werktages beantwortet, wobei Schulferien ausgenommen sind. Pro TRNSYS-Nutzer kann eine Person eingetragen werden jedoch max. 3 Personen, welche technischen Support erhalten. Der Supportvertrag wird automatisch verlängert, wenn keine Kündigung erfolgt.",
      trnsysInfoPageText9: "Modellierungsberatung (wie z.B. Fragen zur bestmöglichen Abbildung von Systemem) ist nicht Bestandteil des technischen Supports.",
      trnsysInfoPageText10: "Der Basissupport für Vorgängerversionen von TRNSYS wird frühestens sechs Monaten und spätestens ein Jahr nach dem Release der nächsten Version, spätestens aber 5 Jahre nach Veröffentlichung der aktuellen Version, eingestellt.",
      trnsysInfoPageText11: "Innerhalb der selben Version sind TRNSYS-Updates kostenfrei.",

      mailBodyTraining: "subject=Schulung&body=Liebes%20Support-Team%2C%0A%20%0Agerne%20w%C3%BCrde%20ich%20folgende%20Personen%20zu%20den%20bevorstehenden%20TRNSYS%20Trainings%20anmelden.%0A%20%0AUser-ID%3A%0AFirma%2FHochschule%2FInstitut%3A%0A%20%0APerson1%0AName%3A%0AE-Mail%3A%0ASchulungsnummer(n)%3A",
    },
  },
  en_US: {
    translation: {
      orderInfoUpdateText: "The reduced price is valid for an update from Trnsys17 to Trnsys18. Please, enter you Trnsys17 user-ID.",
      orderInfoUpdateAddonsText: "The reduced price is valid for an update from Trnsys17 to Trnsys18.",
      userIdLabel: "TRNSYS User-ID (e.g. 17-D0100)",
      userIdLabelAddons: "TRNSYS User-ID (e.g. 18-D0100)",
      "Hochschullizenz": "Educational license",
      "Zeige Gridansicht": "Show grid view",
      "Zeige Tabellenansicht": "Show table view",
      "Bestellung": "Order",
      "Kommerziell": "Commercial",
      "Hochschule": "Educational",
      "Erweiterterer Support (2-Jahre)": "Technical Support (2-years)",
      "er Lizenz, ": " License, ",
      "Summe Gesamt Netto": "Total Net",
      "Ort, Datum": "Place, Date",
      "Unterschrift": "Signature",
      "Zahlungsbedingungen": "Payment conditions",
      "Kostenlos": "Free",
      "Floating Lizenz": "Floating license",

      paymentConditionsText1: "Within Germany, delivery can be made on invoice, payment within 14 days without any reduction, net prices valid from ",
      paymentConditionsText2: " for three months, deliveries abroad only against advance payment.",
      copyrightText: "The software can only be used for own purposes; the program is protected by international law, the copyright is enclosed to: Solar Energy Laboratory, University of Wisconsin (USA) and TRANSSOLAR, Stuttgart.",

      discountUpdateText100: "Discount - Update Libraries from TRNSYS17 to TRNSYS18",
      discountUpdateText50: "Discount - Purchase of Libraries with educational license",
      discountText25: "Discount - Order of 5 - 9 Libraries",
      discountText35: "Discount - Order of 10 - 13 Libraries",
      discountText50: "Discount - Order of 14 Libraries",
      discountTranssolar70: "Discount 70% - Update Transsolar Non-standard Types (max. 440,00 € Total cost)",

      trnsysInfoPageTitle0: "License information:",

      trnsysInfoPageTitle1: "Commercial licenses:",
      trnsysInfoPageTitle2: "Educational licenses:",
      trnsysInfoPageTitle3: "Licensing system:",
      trnsysInfoPageTitle4: "Node-lock",
      trnsysInfoPageTitle5: "Floating license",
      trnsysInfoPageTitle6: "Support:",
      trnsysInfoPageTitle7: "Basic Support",
      trnsysInfoPageTitle8: "Advanced Support",
      trnsysInfoPageTitle9: "Modelling consultancy",
      trnsysInfoPageTitle11: "Updates:",

      trnsysInfoPageText1: "TRNSYS commercial licenses can be purchased in packages of 1, 5, 10, 20, 30, 40, or 50 users for a given site. A site is defined as a department of a given company at a specific location which is the TRNSYS license holder. For the purposes of this license agreement, a location is defined as a physical locale. Branch offices of a single enterprise are considered to be separate locations. All users must be affiliated with the TRNSYS license holder.",
      trnsysInfoPageText2: "Educational licenses are to be for educational and research purposes at degree-granting institutions only (other non-profit organizations such as governments do not count as educational). Each educational license is a site license and can be purchased in packages of 10, 20, 30, 40, 50, 60, or 70 users for that specific department or research group. The TRNSYS license holder is the specific department or research group. TRNSYS may not be distributed to other labs in the same institution. All users must be affiliated with the TRNSYS license holder.",
      trnsysInfoPageText3: "License holders can choose between two licensing options. Please note that mixing of these two variants is not possible.",
      trnsysInfoPageText4: "Each TRNSYS license allows the user to install a single instance of the Software on a given computer. No more users than the number of users of the license purchased have the right to use the Software at a given time (e.g. if a 10-user license is purchased no more than 10 users may use the Software at a given time).",
      trnsysInfoPageText5: "The floating license allows a user to use a license from any machine that can access the license server via the internet and has the program installed. A central license server dynamically allows licenses to float on the network, instead of being tied to a specific computer. This system requires a permanent Internet connection during the use of the software. No more users than the number of users of the license purchased have the right to use the Software at a given time (e.g. if a 10-user license is purchased no more than 10 users may use the Software at a given time).",
      trnsysInfoPageText6: "Registered users of the TRNSYS program may receive technical support. A valid TRNSYS registration number must be included with any request for technical support.",
      trnsysInfoPageText7: "Basic Support regarding installation problems or software bugs are included.",
      trnsysInfoPageText8: "Advanced Support regarding the software (program support) can be bought for time periods of two years. Support requests may be done via E-Mail. We will try two answer requests within one working day, excluding holiday periods. On person per user but max. three persons may be named for contacting our technical support. Support is automatically extended if not resigned.",
      trnsysInfoPageText9: "(e.g. how to model a cerain system in the best way) is not included in the technical support.",
      trnsysInfoPageText10: "Basic support for previous (obsolete) versions of TRNSYS will extend no less than six months and no more than one year after the release of the next version.",
      trnsysInfoPageText11: "Updates within the same version of TRNSYS are included.",

      "Bankverbindung": "Bank details",
      "Geschäftsführer": "CEO",
      "Telefax": "Fax",
      "Fortsetzung folgt auf Seite 2": "To be continued on page 2",
      "Übertrag Summe Seite 1 Netto": "Carryover Total Page 1 Net",
      "Anzahl": "Amount",
      "Beschreibung": "Description",
      "Preis": "Price",
      "Summe Seite 1 Netto": "Total Page 1 Net",
      newsletterText1: "Transsolar Energietechnik GmbH needs your confirmation to send information about Trnsys soft-ware to the e-mail address listed under Delivery Address. Information includes: software updates, trainings, newsletters. You can revoke yourconsent at any time.",
      newsletterText2: "Yes, I would like to receive regular information about TRNSYS software.",
      newsletterText3: "No, I do not want to receive information about TRNSYS software.",
      printOrderText1: "Please print out the order form, sign it and send it to us.",
      printOrderText2: "Thanks for your order!",
      "Startseite": "Home",
      "Mehr Erfahren": "Read More",
      "Lizenz Menge": "License Count",
      "Neue Version": "New version",
      "HINZUFÜGEN": "ADD",
      "ENTFERNEN": "REMOVE",
      "Suche": "Search",
      "Weiterlesen": "Read More",
      "Kurzbeschreibung": "Short-Description",
      "Bestellinfos": "Order infos",
      "Lizenz Typ": "License Type",
      "Lieferanschrift": "Shipping Address",
      "Rechnungsanschrift": "Billing Address",
      "Bestellformular drucken": "Print order form",
      "Zurück zur FAQ Übersicht": "Back to the FAQ overview",
      "Bestellen": "Order",
      "Weiter": "Next",
      "Zurück": "Back",
      "Bestellformular erstellen": "Create order form",
      addressSameText: "The Billing address is the same as the shipping address.",
      "Vorname": "First name",
      "Nachname": "Last name",
      "Straße und Hausnummer": "Street number",
      "Postleitzahl": "Postal code",
      "Stadt": "City",
      "Telefon": "Phone",
      "Fax": "Fax",
      "Firma / Hochschule": "Organization",
      "Abteilung / Institut": "Department",
      addedSupportText: "Add the advanced support for 600€.",
      supportText: "Please add 3 E-Mails, that will receive the advanced support.",
      "Bestellformular": "Order_form",
      "Land": "Country",
      "Sprache": "Language",
      "Zurück zur Projekt Übersicht": "Back to project overview",
      "ANFRAGE": "REQUEST",
      "Angebot": "Quotation",
      mailBodyTraining: "subject=Training&body=Dear%20support%20team%2C%0A%20%0AI%20would%20like%20to%20register%20the%20following%20persons%20for%20the%20upcoming%20TRNSYS%20trainings.%0A%20%0AUser%20ID%3A%0ACompany%2FUniversity%2FInstitute%3A%0A%20%0APerson1%0AName%3A%0AE-mail%3A%0ACourse%20number(s)%3A",
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "de_DE",


    keySeparator: true, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    //
    react: {
      wait: true,
    },
  })

export default i18n
