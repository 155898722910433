module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Trnsys - Eine Software von Transsolar","short_name":"Trnsys","description":"Trnsys ist eine Simulations-Software, die von Transsolar entwickelt und vertrieben wird.","lang":"de_DE","start_url":"/","background_color":"#FFAC33","theme_color":"#FFCA28","display":"minimal-ui","icon":"src/assets/svg/trnsys-logo.svg","localize":[{"start_url":"/en/","lang":"en_US","name":"Trnsys - A Software by Transsolar","short_name":"Trnsys","description":"Trnsys is a simulation Software developed and distributed by Transsolar."}],"icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(excluded-link|external)"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
