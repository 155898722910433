import * as actionType from "./actionTypes"
import initialState from "./initialState"

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.TOGGLE_LANGUAGE:
      return {
        ...state,
        lang: state.lang === "de_DE" ? "en_US" : "de_DE"
      };
    case actionType.SET_TO_LANGUAGE:
      return {
        ...state,
        lang: action.payload
      };
    case actionType.SET_CORRESPONDING_LANG_SLUG:
      return {
        ...state,
        correspondingLangSlug: action.payload
      };
    case actionType.SET_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload
      };
    case actionType.SET_BILLING_ADDRESS:
      return {
        ...state,
        billingAddress: action.payload
      };
    case actionType.SET_BILLING_IS_SAME:
      return {
        ...state,
        billingIsSame: action.payload
      };
    case actionType.SET_LICENSE_TYPE:
      return {
        ...state,
        orderInfo: {
          ...state.orderInfo,
          licenseType: action.payload,
        }
      };
    case actionType.SET_USER_ID:
      return {
        ...state,
        orderInfo: {
          ...state.orderInfo,
          userId: action.payload,
        }
      };
    case actionType.SET_UST:
      return {
        ...state,
        orderInfo: {
          ...state.orderInfo,
          ust: action.payload,
        }
      };
    case actionType.SET_ORDER_TYPE:
      return {
        ...state,
        orderInfo: {
          ...state.orderInfo,
          orderType: action.payload,
        }
      };
    case actionType.SET_LICENSE_AMOUNT:
      return {
        ...state,
        orderInfo: {
          ...state.orderInfo,
          licenseAmount: action.payload,
        }
      };
    case actionType.SET_PRICE_CALCULATED:
      return {
        ...state,
        orderInfo: {
          ...state.orderInfo,
          priceCalculated: action.payload,
        }
      };
    case actionType.SET_IS_UPDATE:
      return {
        ...state,
        orderInfo: {
          ...state.orderInfo,
          isUpdate: action.payload,
        }
      };
    case actionType.SET_IS_UPDATE_ADDONS:
      return {
        ...state,
        orderInfo: {
          ...state.orderInfo,
          isUpdateAddons: action.payload,
        }
      };
    case actionType.SET_IS_EDUCATIONAL:
      return {
        ...state,
        orderInfo: {
          ...state.orderInfo,
          isEducational: action.payload,
        }
      };
    case actionType.SET_IS_EDUCATIONAL_ADDONS:
      return {
        ...state,
        orderInfo: {
          ...state.orderInfo,
          isEducationalAddons: action.payload,
        }
      };
    case actionType.SET_WANTS_NEWSLETTER:
      return {
        ...state,
        orderInfo: {
          ...state.orderInfo,
          wantsNewsletter: action.payload,
        }
      };
    case actionType.SET_SUPPORT_INFO:
      return {
        ...state,
        supportInfo: action.payload
      };
    case actionType.SET_SUPPORT_EMAILS:
      return {
        ...state,
        supportInfo: {
          ...state.supportInfo,
          email1: action.payload.email1,
          email2: action.payload.email2,
          email3: action.payload.email3,
        }
      };
    case actionType.SET_ADVANCED_SUPPORT:
      return {
        ...state,
        supportInfo: {
          ...state.supportInfo,
          advancedSupport: action.payload
        }
      };
    case actionType.RESET_ORDER:
      return {
        ...state,
        orderInfo: initialState.orderInfo,
        supportInfo: initialState.supportInfo,
        licenseType: initialState.licenseType,
        shippingAddress: initialState.shippingAddress,
        billingAddress: initialState.billingAddress,
        billingIsSame: initialState.billingIsSame,
      };
    case actionType.SET_ADDON_ORDERS:
      return {
        ...state,
        addonOrders: action.payload
      };
    case actionType.UPDATE_ADDON_COUNT:
      if (!action.payload.id) return state
      return {
        ...state,
        addonOrders: state.addonOrders.map(addon =>
          addon.id === action.payload.id ? { ...addon, count: action.payload.count } : addon
        )
      };
    case actionType.SET_SHOW_ORDER_FAB:
      if (action.payload === state.showOrderFab || state.showOrderFab === 2) return state

      return {
        ...state,
        showOrderFab: action.payload
      }
    default:
      return state;
  }

}

export default reducer
