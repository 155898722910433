/*
 * action types
 */

export const TOGGLE_LANGUAGE = 'TOGGLE_LANGUAGE';
export const SET_TO_LANGUAGE = 'SET_TO_LANGUAGE';
export const SET_CORRESPONDING_LANG_SLUG = 'SET_CORRESPONDING_LANG_SLUG';
export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS';
export const SET_BILLING_ADDRESS = 'SET_BILLING_ADDRESS';
export const SET_LICENSE_TYPE = 'SET_LICENSE_TYPE';
export const SET_BILLING_IS_SAME = 'SET_BILLING_IS_SAME';
export const RESET_ORDER = 'RESET_ORDER'
export const SET_USER_ID = 'SET_USER_ID'
export const SET_IS_UPDATE = 'SET_IS_UPDATE'
export const SET_IS_UPDATE_ADDONS = 'SET_IS_UPDATE_ADDONS'
export const SET_SUPPORT_INFO = 'SET_SUPPORT_INFO'
export const SET_ORDER_TYPE = 'SET_ORDER_TYPE'
export const SET_IS_EDUCATIONAL = 'SET_IS_EDUCATIONAL'
export const SET_IS_EDUCATIONAL_ADDONS = 'SET_IS_EDUCATIONAL_ADDONS'
export const SET_SUPPORT_EMAILS = 'SET_SUPPORT_EMAILS'
export const SET_ADVANCED_SUPPORT = 'SET_ADVANCED_SUPPORT'
export const SET_LICENSE_AMOUNT = 'SET_LICENSE_AMOUNT'
export const SET_PRICE_CALCULATED = 'SET_PRICE_CALCULATED'
export const SET_WANTS_NEWSLETTER = 'SET_WANTS_NEWSLETTER'
export const SET_UST = 'SET_UST'
export const SET_ADDON_ORDERS = 'SET_ADDON_ORDERS'
export const UPDATE_ADDON_COUNT = 'UPDATE_ADDON_COUNT'
export const SET_SHOW_ORDER_FAB = 'SET_SHOW_ORDER_FAB'
