import React from "react"
import { Provider } from "react-redux"

import createStore from "./src/state/store"
import "./src/components/i18n"
import {ApolloClient, InMemoryCache, ApolloProvider, HttpLink} from "@apollo/client"
import fetch from "isomorphic-fetch"


// generated by Fragment Matcher plugin
import possibleTypes from './apollo/possibleTypes.json';

const cacheOptions = {
  possibleTypes: possibleTypes,
}

const client = new ApolloClient({
  link: new HttpLink({
    uri: "https://backend101.trnsys.de/graphql",
    fetch
  }),
  cache: new InMemoryCache(cacheOptions),
})

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore()
  return <ApolloProvider client={client}>
    <Provider store={store}>
      {element}
    </Provider>
  </ApolloProvider>
}
